<template>
  <div>
    <v-card class="greeting-card">
      <v-row class="ma-0 pa-0">
        <v-card-text>
          <v-img
            :src="require('@/assets/images/logos/main-logo.png')"
            max-height="800"
            alt="logo"
            contain
            eager
            class="app-logo"
          ></v-img>
          <!--          <v-img-->
          <!--            :src="require('@/assets/images/logos/main-logo.png')"-->
          <!--            max-height="800"-->
          <!--            alt="logo"-->
          <!--            contain-->
          <!--            eager-->
          <!--            class="app-logo"-->
          <!--          ></v-img>-->
        </v-card-text>
      </v-row>
    </v-card>
    <v-dialog v-model="is_change_password" persistent max-width="50%">
      <ChangePassword></ChangePassword>
    </v-dialog>
  </div>
</template>

<script>
  import {mapActions, mapGetters} from "vuex";
  import ChangePassword from "@/views/dashboard/ChangePassword";

  export default {
    computed: {
      ...mapGetters('authentication', ['position', 'need_change_password', 'user_id',]),
    },
    components: {
      ChangePassword,
    },
    data() {
      return {
        is_change_password: false,
      }
    },
    mounted() {
      this.is_change_password = this.need_change_password
    },
  }
</script>
